/*!
 * Accessible Datepicker v2.1.20
 * Copyright 2015-2019 Eureka2, Jacques Archimède.
 * Based on the example of the Open AJAX Alliance Accessibility Tools Task Force : http://www.oaa-accessibility.org/examplep/datepicker1/
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Inspired by :
 * http://wet-boew.github.io/wet-boew/demos/datepicker/datepicker-fr.html
 * http://eternicode.github.io/bootstrap-datepicker
 */
(function() {
  "use strict";
  typeof Date.dp_locales == "undefined" &&
    (Date.dp_locales = {
      texts: {
        buttonTitle: "Select date ...",
        buttonLabel: "Click or press the Enter key or the spacebar to open the calendar",
        prevButtonLabel: "Go to previous month",
        prevMonthButtonLabel: "Go to the previous year",
        prevYearButtonLabel: "Go to the previous twenty years",
        nextButtonLabel: "Go to next month",
        nextMonthButtonLabel: "Go to the next year",
        nextYearButtonLabel: "Go to the next twenty years",
        changeMonthButtonLabel: "Click or press the Enter key or the spacebar to change the month",
        changeYearButtonLabel: "Click or press the Enter key or the spacebar to change the year",
        changeRangeButtonLabel: "Click or press the Enter key or the spacebar to go to the next twenty years",
        closeButtonTitle: "Close",
        closeButtonLabel: "Close the calendar",
        calendarHelp:
          "- Up Arrow and Down Arrow - goes to the same day of the week in the previous or next week respectively. If the end of the month is reached, continues into the next or previous month as appropriate.\r\n- Left Arrow and Right Arrow - advances one day to the next, also in a continuum. Visually focus is moved from day to day and wraps from row to row in the grid of days.\r\n- Control+Page Up - Moves to the same date in the previous year.\r\n- Control+Page Down - Moves to the same date in the next year.\r\n- Home - Moves to the first day of the current month.\r\n- End - Moves to the last day of the current month.\r\n- Page Up - Moves to the same date in the previous month.\r\n- Page Down - Moves to the same date in the next month.\r\n- Enter or Espace - closes the calendar, and the selected date is shown in the associated text box.\r\n- Escape - closes the calendar without any action."
      },
      directionality: "LTR",
      month_names: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      month_names_abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      month_names_narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
      day_names: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      day_names_abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      day_names_short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      day_names_narrow: ["S", "M", "T", "W", "T", "F", "S"],
      day_periods: { am: "AM", noon: "noon", pm: "PM" },
      day_periods_abbreviated: { am: "AM", noon: "noon", pm: "PM" },
      day_periods_narrow: { am: "a", noon: "n", pm: "p" },
      quarter_names: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"],
      quarter_names_abbreviated: ["Q1", "Q2", "Q3", "Q4"],
      quarter_names_narrow: ["1", "2", "3", "4"],
      era_names: ["Before Christ", "Anno Domini"],
      era_names_abbreviated: ["BC", "AD"],
      era_names_narrow: ["B", "A"],
      full_format: "EEEE, MMMM d, y",
      long_format: "MMMM d, y",
      medium_format: "MMM d, y",
      short_format: "M/d/yy",
      firstday_of_week: 0
    });
})(),
  (function(n) {
    if (typeof define == "function" && define.amd) define(["jquery"], n);
    else if (typeof exports == "object") {
      n(require("jquery"));
    } else {
      if (typeof jQuery == "undefined") throw new Error("Datepicker's JavaScript requires jQuery");
      n(jQuery);
    }
  })(function(n, t) {
    ("use strict");
    var u = [
        '<a class="datepicker-button bootstrap3 input-group-addon btn" role="button" aria-haspopup="true" tabindex="0" aria-labelledby="datepicker-bn-open-label-CALENDARID">',
        '\t<span class="glyphicon glyphicon-calendar" title="Select Date..."></span>',
        "</a>"
      ],
      f = [
        '<div class="datepicker-calendar bootstrap3" id="datepicker-calendar-CALENDARID" aria-hidden="false">',
        '\t<div class="datepicker-month-wrap">',
        '\t\t<div class="datepicker-button datepicker-month-fast-next pull-right" role="button" aria-labelledby="datepicker-bn-fast-next-label-CALENDARID" tabindex="0"><span class="glyphicon glyphicon-forward"></span></div>',
        '\t\t<div class="datepicker-button datepicker-month-next pull-right" role="button" aria-labelledby="datepicker-bn-next-label-CALENDARID" tabindex="0"><span class="glyphicon glyphicon-triangle-right"></span></div>',
        '\t\t<div class="datepicker-button datepicker-month-fast-prev pull-left" role="button" aria-labelledby="datepicker-bn-fast-prev-label-CALENDARID" tabindex="0"><span class="glyphicon glyphicon-backward"></span></div>',
        '\t\t<div class="datepicker-button datepicker-month-prev pull-left" role="button" aria-labelledby="datepicker-bn-prev-label-CALENDARID" tabindex="0"><span class="glyphicon glyphicon-triangle-left"></span></div>',
        '\t\t<div id="datepicker-month-CALENDARID" class="datepicker-button datepicker-month" tabindex="0" role="heading" aria-live="assertive" aria-atomic="true" title="Click or press the Enter key or the spacebar to change the month">July 2015</div>',
        "\t</div>",
        '\t<table class="datepicker-grid" role="grid" aria-readonly="true" aria-activedescendant="datepicker-err-msg-CALENDARID" aria-labelledby="datepicker-month-CALENDARID" tabindex="0">',
        '\t\t<thead role="presentation">',
        '\t\t\t<tr class="datepicker-weekdays" role="row">',
        '\t\t\t\t<th scope="col" id="day0-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Sunday"><abbr title="Sunday">Su</abbr></th>',
        '\t\t\t\t<th scope="col" id="day1-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Monday"><abbr title="Monday">Mo</abbr></th>',
        '\t\t\t\t<th scope="col" id="day2-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Tuesday"><abbr title="Tuesday">Tu</abbr></th>',
        '\t\t\t\t<th scope="col" id="day3-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Wednesday"><abbr title="Wednesday">We</abbr></th>',
        '\t\t\t\t<th scope="col" id="day4-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Thursday"><abbr title="Thursday">Th</abbr></th>',
        '\t\t\t\t<th scope="col" id="day5-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Friday"><abbr title="Friday">Fr</abbr></th>',
        '\t\t\t\t<th scope="col" id="day6-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Saturday"><abbr title="Saturday">Sa</abbr></th>',
        "\t\t\t</tr>",
        "\t\t</thead>",
        '\t\t<tbody role="presentation">',
        "\t\t\t<tr>",
        '\t\t\t\t<td id="datepicker-err-msg-CALENDARID" colspan="7">Javascript must be enabled</td>',
        "\t\t\t</tr>",
        "\t\t</tbody>",
        "\t</table>",
        '\t<div class="datepicker-close-wrap">',
        '\t\t<button class="datepicker-button datepicker-close" id="datepicker-close-CALENDARID" aria-labelledby="datepicker-bn-close-label-CALENDARID">Close</button>',
        "\t</div>",
        '\t<div id="datepicker-bn-open-label-CALENDARID" class="datepicker-bn-open-label offscreen">Click or press the Enter key or the spacebar to open the calendar</div>',
        '\t<div id="datepicker-bn-prev-label-CALENDARID" class="datepicker-bn-prev-label offscreen">Go to previous month</div>',
        '\t<div id="datepicker-bn-next-label-CALENDARID" class="datepicker-bn-next-label offscreen">Go to next month</div>',
        '\t<div id="datepicker-bn-fast-prev-label-CALENDARID" class="datepicker-bn-fast-prev-label offscreen">Go to previous year</div>',
        '\t<div id="datepicker-bn-fast-next-label-CALENDARID" class="datepicker-bn-fast-next-label offscreen">Go to next year</div>',
        '\t<div id="datepicker-bn-close-label-CALENDARID" class="datepicker-bn-close-label offscreen">Close the date picker</div>',
        "</div>"
      ],
      e = [
        '<a class="datepicker-button bootstrap4 input-group-append" role="button" aria-haspopup="true" tabindex="0" aria-labelledby="datepicker-bn-open-label-CALENDARID">',
        '\t<span class="input-group-text"><i class="far fa-calendar-alt" title="Select Date..."></i></span>',
        "</a>"
      ],
      o = [
        '<div class="datepicker-calendar bootstrap4" id="datepicker-calendar-CALENDARID" aria-hidden="false">',
        '\t<div class="datepicker-month-wrap">',
        '\t\t<div class="datepicker-button datepicker-month-fast-next float-right" role="button" aria-labelledby="datepicker-bn-fast-next-label-CALENDARID" tabindex="0"><i class="fas fa-forward"></i></div>',
        '\t\t<div class="datepicker-button datepicker-month-next float-right" role="button" aria-labelledby="datepicker-bn-next-label-CALENDARID" tabindex="0"><i class="fas fa-caret-right"></i></div>',
        '\t\t<div class="datepicker-button datepicker-month-fast-prev float-left" role="button" aria-labelledby="datepicker-bn-fast-prev-label-CALENDARID" tabindex="0"><i class="fas fa-backward"></i></div>',
        '\t\t<div class="datepicker-button datepicker-month-prev float-left" role="button" aria-labelledby="datepicker-bn-prev-label-CALENDARID" tabindex="0"><i class="fas fa-caret-left"></i></div>',
        '\t\t<div id="datepicker-month-CALENDARID" class="datepicker-button datepicker-month" tabindex="0" role="heading" aria-live="assertive" aria-atomic="true" title="Click or press the Enter key or the spacebar to change the month">July 2015</div>',
        "\t</div>",
        '\t<table class="datepicker-grid" role="grid" aria-readonly="true" aria-activedescendant="datepicker-err-msg-CALENDARID" aria-labelledby="datepicker-month-CALENDARID" tabindex="0">',
        '\t\t<thead role="presentation">',
        '\t\t\t<tr class="datepicker-weekdays" role="row">',
        '\t\t\t\t<th scope="col" id="day0-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Sunday"><abbr title="Sunday">Su</abbr></th>',
        '\t\t\t\t<th scope="col" id="day1-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Monday"><abbr title="Monday">Mo</abbr></th>',
        '\t\t\t\t<th scope="col" id="day2-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Tuesday"><abbr title="Tuesday">Tu</abbr></th>',
        '\t\t\t\t<th scope="col" id="day3-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Wednesday"><abbr title="Wednesday">We</abbr></th>',
        '\t\t\t\t<th scope="col" id="day4-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Thursday"><abbr title="Thursday">Th</abbr></th>',
        '\t\t\t\t<th scope="col" id="day5-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Friday"><abbr title="Friday">Fr</abbr></th>',
        '\t\t\t\t<th scope="col" id="day6-header-CALENDARID" class="datepicker-day" role="columnheader" aria-label="Saturday"><abbr title="Saturday">Sa</abbr></th>',
        "\t\t\t</tr>",
        "\t\t</thead>",
        '\t\t<tbody role="presentation">',
        "\t\t\t<tr>",
        '\t\t\t\t<td id="datepicker-err-msg-CALENDARID" colspan="7">Javascript must be enabled</td>',
        "\t\t\t</tr>",
        "\t\t</tbody>",
        "\t</table>",
        '\t<div class="datepicker-close-wrap">',
        '\t\t<button class="datepicker-button datepicker-close" id="datepicker-close-CALENDARID" aria-labelledby="datepicker-bn-close-label-CALENDARID">Close</button>',
        "\t</div>",
        '\t<div id="datepicker-bn-open-label-CALENDARID" class="datepicker-bn-open-label offscreen">Click or press the Enter key or the spacebar to open the calendar</div>',
        '\t<div id="datepicker-bn-prev-label-CALENDARID" class="datepicker-bn-prev-label offscreen">Go to previous month</div>',
        '\t<div id="datepicker-bn-next-label-CALENDARID" class="datepicker-bn-next-label offscreen">Go to next month</div>',
        '\t<div id="datepicker-bn-fast-prev-label-CALENDARID" class="datepicker-bn-fast-prev-label offscreen">Go to previous year</div>',
        '\t<div id="datepicker-bn-fast-next-label-CALENDARID" class="datepicker-bn-fast-next-label offscreen">Go to next year</div>',
        '\t<div id="datepicker-bn-close-label-CALENDARID" class="datepicker-bn-close-label offscreen">Close the date picker</div>',
        "</div>"
      ],
      i = function(t, r) {
        var s = this,
          h,
          c,
          a,
          l;
        this.$target = n(t);
        this.options = n.extend({}, i.DEFAULTS, r);
        this.locales = Date.dp_locales;
        this.startview(this.options.startView);
        typeof this.options.inputFormat == "string" && (this.options.inputFormat = [this.options.inputFormat]);
        Array.isArray(this.options.datesDisabled) || (this.options.datesDisabled = [this.options.datesDisabled]);
        n.each(this.options.datesDisabled, function(n, t) {
          if (typeof t == "string") {
            var i = s.parseDate(t);
            s.options.datesDisabled[n] = i === null ? null : s.format(i);
          } else s.options.datesDisabled[n] = t instanceof Date && !isNaN(t.valueOf()) ? s.format(t) : null;
        });
        this.options.min != null
          ? (this.options.min = this.parseDate(this.options.min))
          : this.$target.attr("min") && (this.options.min = this.parseDate(this.$target.attr("min")));
        this.options.max != null
          ? (this.options.max = this.parseDate(this.options.max))
          : this.$target.attr("max") && (this.options.max = this.parseDate(this.$target.attr("max")));
        typeof this.options.previous == "string"
          ? (this.options.previous = n(this.options.previous))
          : this.options.previous instanceof n || (this.options.previous = null);
        typeof this.options.next == "string"
          ? (this.options.next = n(this.options.next))
          : this.options.next instanceof n || (this.options.next = null);
        this.id = this.$target.attr("id") || "datepicker-" + Math.floor(Math.random() * 1e5);
        h = this.options.markup == "bootstrap3" ? f.join("") : o.join("");
        h = h.replace(/CALENDARID/g, this.id + "");
        this.$target.parent(".input-group").length == 0 && this.$target.wrap('<div class="input-group"></div>');
        this.$group = this.$target.parent(".input-group");
        this.$target.attr("aria-autocomplete", "none");
        this.$target.css("min-width", "7em");
        this.$target.addClass("form-control");
        this.$target.attr("placeholder") || this.$target.attr("placeholder", this.options.inputFormat[0]);
        c = this.options.markup == "bootstrap3" ? u.join("") : e.join("");
        c = c.replace(/CALENDARID/g, this.id + "");
        this.$button = n(c);
        this.$button.addClass(this.options.theme);
        this.$calendar = n(h);
        this.$calendar.addClass(this.options.theme);
        this.options.buttonLeft ? this.$target.before(this.$button) : this.$target.after(this.$button);
        this.$calendar.parent().css("position") === "static" && this.$calendar.parent().css("position", "relative");
        this.$calendar.find(".datepicker-bn-open-label").html(this.options.buttonLabel);
        this.$target.attr("id") && this.$calendar.attr("aria-controls", this.$target.attr("id"));
        this.$button.find("span").attr("title", this.options.buttonTitle);
        this.$calendar.css("left", this.$target.parent().position().left + "px");
        this.$monthObj = this.$calendar.find(".datepicker-month");
        this.$prev = this.$calendar.find(".datepicker-month-prev");
        this.$next = this.$calendar.find(".datepicker-month-next");
        this.$fastprev = this.$calendar.find(".datepicker-month-fast-prev");
        this.$fastnext = this.$calendar.find(".datepicker-month-fast-next");
        this.$grid = this.$calendar.find(".datepicker-grid");
        this.locales.directionality === "RTL" && this.$grid.addClass("rtl");
        a = this.$grid.find("th.datepicker-day abbr");
        this.drawCalendarHeader();
        this.options.inline == !1 && this.options.modal == !0
          ? ((this.$close = this.$calendar.find(".datepicker-close")),
            this.$close.html(this.options.closeButtonTitle).attr("title", this.options.closeButtonLabel),
            this.$calendar.find(".datepicker-bn-close-label").html(this.options.closeButtonLabel))
          : (this.hideObject(this.$calendar.find(".datepicker-close-wrap")),
            this.hideObject(this.$calendar.find(".datepicker-bn-close-label")));
        this.options.inline != !1
          ? (this.hideObject(this.$button),
            (l = typeof this.options.inline == "string" ? n("#" + this.options.inline) : this.options.inline),
            l.append(this.$calendar),
            this.$calendar.css({ position: "relative", left: "0px" }),
            this.initializeDate())
          : (this.$calendar.css({ display: "none" }),
            this.$target.parent().after(this.$calendar),
            this.hide(!this.options.gainFocusOnConstruction));
        this.keys = {
          tab: 9,
          enter: 13,
          esc: 27,
          space: 32,
          pageup: 33,
          pagedown: 34,
          end: 35,
          home: 36,
          left: 37,
          up: 38,
          right: 39,
          down: 40
        };
        this.bindHandlers();
        this.$button.on("click", function(t) {
          return (
            n(this).hasClass("disabled") ||
              (s.$calendar.attr("aria-hidden") === "true" ? (s.initializeDate(), s.show()) : s.hide(),
              s.selectGridCell(s.$grid.attr("aria-activedescendant"))),
            t.stopPropagation(),
            !1
          );
        });
        this.$button.on("keydown", function(t) {
          var i = t || event;
          if (i.keyCode == s.keys.enter || i.keyCode == s.keys.space) return n(this).trigger("click"), !1;
        });
        this.$calendar.on("blur", function() {
          s.$calendar.attr("aria-hidden") === "false" && s.hide();
        });
      },
      r;
    i.VERSION = "2.1.20";
    i.DEFAULTS = {
      firstDayOfWeek: Date.dp_locales.firstday_of_week,
      weekDayFormat: "short",
      startView: 0,
      daysOfWeekDisabled: [],
      datesDisabled: [],
      isDateDisabled: null,
      isMonthDisabled: null,
      isYearDisabled: null,
      inputFormat: [Date.dp_locales.short_format],
      outputFormat: Date.dp_locales.short_format,
      titleFormat: Date.dp_locales.full_format,
      buttonLeft: !1,
      buttonTitle: Date.dp_locales.texts.buttonTitle,
      buttonLabel: Date.dp_locales.texts.buttonLabel,
      prevButtonLabel: Date.dp_locales.texts.prevButtonLabel,
      prevMonthButtonLabel: Date.dp_locales.texts.prevMonthButtonLabel,
      prevYearButtonLabel: Date.dp_locales.texts.prevYearButtonLabel,
      nextButtonLabel: Date.dp_locales.texts.nextButtonLabel,
      nextMonthButtonLabel: Date.dp_locales.texts.nextMonthButtonLabel,
      nextYearButtonLabel: Date.dp_locales.texts.nextYearButtonLabel,
      changeMonthButtonLabel: Date.dp_locales.texts.changeMonthButtonLabel,
      changeYearButtonLabel: Date.dp_locales.texts.changeYearButtonLabel,
      changeRangeButtonLabel: Date.dp_locales.texts.changeRangeButtonLabel,
      closeButtonTitle: Date.dp_locales.texts.closeButtonTitle,
      closeButtonLabel: Date.dp_locales.texts.closeButtonLabel,
      onUpdate: function() {},
      previous: null,
      next: null,
      allowSameDate: !0,
      markup: "bootstrap3",
      theme: "default",
      modal: !1,
      inline: !1,
      gainFocusOnConstruction: !0,
      min: null,
      max: null
    };
    i.prototype.initializeDate = function() {
      var n = this.$target.val(),
        t = n === "" ? new Date() : this.parseDate(n);
      this.setDate(t, !0);
    };
    i.prototype.getDate = function() {
      var n = this.$target.val();
      return n === "" ? new Date() : this.parseDate(n);
    };
    i.prototype.setDate = function(n, t) {
      this.dateObj = n;
      t = typeof t == "undefined" ? !1 : t;
      this.dateObj == null &&
        (this.$target.attr("aria-invalid", !0),
        this.$target.parents(".form-group").addClass("has-error"),
        (this.dateObj = new Date()),
        this.dateObj.setHours(0, 0, 0, 0));
      this.options.min != null && this.dateObj < this.options.min
        ? (this.$target.attr("aria-invalid", !0),
          this.$target.parents(".form-group").addClass("has-error"),
          (this.dateObj = this.options.min))
        : this.options.max != null &&
          this.dateObj > this.options.max &&
          (this.$target.attr("aria-invalid", !0),
          this.$target.parents(".form-group").addClass("has-error"),
          (this.dateObj = this.options.max));
      (t && this.$target.val() == "") || this.$target.val(this.format(this.dateObj));
      this.curYear = this.dateObj.getFullYear();
      this.year = this.curYear;
      this.curMonth = this.dateObj.getMonth();
      this.month = this.curMonth;
      this.date = this.dateObj.getDate();
      switch (this.options.startView) {
        case 1:
          this.populateMonthsCalendar();
          this.$grid.attr("aria-activedescendant", this.$grid.find(".curMonth").attr("id"));
          break;
        case 2:
          this.populateYearsCalendar();
          this.$grid.attr("aria-activedescendant", this.$grid.find(".curYear").attr("id"));
          break;
        default:
          this.populateDaysCalendar();
          this.$grid.attr("aria-activedescendant", this.$grid.find(".curDay").attr("id"));
      }
    };
    i.prototype.drawCalendarHeader = function() {
      for (var i = this.$grid.find("th.datepicker-day"), n = this.options.firstDayOfWeek, t = 0; t < 7; t++)
        i.eq(t).attr("aria-label", this.locales.day_names[n]),
          i
            .children("abbr")
            .eq(t)
            .attr("title", this.locales.day_names[n])
            .text(
              this.options.weekDayFormat === "short"
                ? this.locales.day_names_short[n]
                : this.locales.day_names_narrow[n]
            ),
          (n = (n + 1) % 7);
    };
    i.prototype.populateDaysCalendar = function() {
      var f, s, v, y, c;
      this.$calendar.find(".datepicker-bn-prev-label").html(this.options.prevButtonLabel);
      this.$calendar.find(".datepicker-bn-next-label").html(this.options.nextButtonLabel);
      this.$calendar.find(".datepicker-bn-fast-prev-label").html(this.options.prevMonthButtonLabel);
      this.$calendar.find(".datepicker-bn-fast-next-label").html(this.options.nextMonthButtonLabel);
      this.options.min != null &&
      (this.year - 1 < this.options.min.getFullYear() ||
        (this.year - 1 == this.options.min.getFullYear() && this.month < this.options.min.getMonth()))
        ? (this.$fastprev.attr("title", ""), this.$fastprev.addClass("disabled"), this.$fastprev.removeClass("enabled"))
        : (this.$fastprev.attr("title", this.options.prevMonthButtonLabel),
          this.$fastprev.addClass("enabled"),
          this.$fastprev.removeClass("disabled"));
      f = this.previousMonth(this.year, this.month);
      this.options.min != null &&
      (f.year < this.options.min.getFullYear() ||
        (f.year == this.options.min.getFullYear() && f.month < this.options.min.getMonth()))
        ? (this.$prev.attr("title", ""), this.$prev.addClass("disabled"), this.$prev.removeClass("enabled"))
        : (this.$prev.attr("title", this.options.prevButtonLabel),
          this.$prev.addClass("enabled"),
          this.$prev.removeClass("disabled"));
      this.$monthObj.attr("title", this.options.changeMonthButtonLabel);
      s = this.nextMonth(this.year, this.month);
      this.options.max != null &&
      (s.year > this.options.max.getFullYear() ||
        (s.year == this.options.max.getFullYear() && s.month > this.options.max.getMonth()))
        ? (this.$next.attr("title", ""), this.$next.addClass("disabled"), this.$next.removeClass("enabled"))
        : (this.$next.attr("title", this.options.nextButtonLabel),
          this.$next.addClass("enabled"),
          this.$next.removeClass("disabled"));
      this.options.max != null &&
      (this.year + 1 > this.options.max.getFullYear() ||
        (this.year + 1 == this.options.max.getFullYear() && this.month > this.options.max.getMonth()))
        ? (this.$fastnext.attr("title", ""), this.$fastnext.addClass("disabled"), this.$fastnext.removeClass("enabled"))
        : (this.$fastnext.attr("title", this.options.nextMonthButtonLabel),
          this.$fastnext.addClass("enabled"),
          this.$fastnext.removeClass("disabled"));
      this.showObject(this.$fastprev);
      this.showObject(this.$fastnext);
      var h = this.getDaysInMonth(this.year, this.month),
        w = this.getDaysInMonth(f.year, f.month),
        b = new Date(this.year, this.month, 1).getDay(),
        l = (this.options.firstDayOfWeek + 6) % 7,
        t = 1,
        a = 1;
      this.$monthObj.html(this.locales.month_names[this.month] + " " + this.year);
      this.showObject(this.$grid.find("thead"));
      for (var i = '\t<tr id="row0-' + this.id + '" role="row">\n', e = 0, r = this.options.firstDayOfWeek; r != b; )
        e++, (r = (r + 1) % 7);
      for (; e > 0; e--) i += '\t\t<td class="empty">' + (w - e + 1) + "</td>\n";
      for (
        v = this.options.isYearDisabled && this.options.isYearDisabled(this.year),
          y = this.options.isMonthDisabled && this.options.isMonthDisabled(this.year, this.month + 1),
          t = 1;
        t <= h;
        t++
      ) {
        var o = new Date(this.year, this.month, t, 0, 0, 0, 0),
          p = this.formatDate(o, this.options.titleFormat),
          u = t == this.date && this.month == this.curMonth && this.year == this.curYear ? " curDay" : "";
        i +=
          v || y
            ? '\t\t<td id="cell' + t + "-" + this.id + '" class="day unselectable' + u + '"'
            : n.inArray(r, this.options.daysOfWeekDisabled) > -1
            ? '\t\t<td id="cell' + t + "-" + this.id + '" class="day unselectable' + u + '"'
            : this.options.min != null && o < this.options.min
            ? '\t\t<td id="cell' + t + "-" + this.id + '" class="day unselectable' + u + '"'
            : this.options.max != null && o > this.options.max
            ? '\t\t<td id="cell' + t + "-" + this.id + '" class="day unselectable' + u + '"'
            : n.inArray(this.format(o), this.options.datesDisabled) > -1
            ? '\t\t<td id="cell' + t + "-" + this.id + '" class="day unselectable' + u + '"'
            : this.options.isDateDisabled && this.options.isDateDisabled(o)
            ? '\t\t<td id="cell' + t + "-" + this.id + '" class="day unselectable' + u + '"'
            : '\t\t<td id="cell' + t + "-" + this.id + '" class="day selectable' + u + '"';
        i += ' data-value="' + t + '"';
        i += ' title="' + p + '"';
        i += ' aria-label="' + p + '"';
        i += ' headers="day' + r + "-header-" + this.id + '" role="gridcell" tabindex="-1" aria-selected="false">' + t;
        i += "</td>";
        r == l && t < h && ((i += '\t</tr>\n\t<tr id="row' + a + "-" + this.id + '" role="row">\n'), a++);
        t < h && (r = (r + 1) % 7);
      }
      while (r != l) (i += '\t\t<td class="empty">' + ++e + "</td>\n"), (r = (r + 1) % 7);
      i += "\t</tr>";
      c = this.$grid.find("tbody");
      c.empty();
      c.append(i);
      this.gridType = 0;
    };
    i.prototype.populateMonthsCalendar = function() {
      var i, f;
      this.$calendar.find(".datepicker-bn-prev-label").html(this.options.prevMonthButtonLabel);
      this.$calendar.find(".datepicker-bn-next-label").html(this.options.nextMonthButtonLabel);
      this.hideObject(this.$fastprev);
      this.hideObject(this.$fastnext);
      this.options.min != null && this.year - 1 < this.options.min.getFullYear()
        ? (this.$prev.attr("title", ""), this.$prev.addClass("disabled"), this.$prev.removeClass("enabled"))
        : (this.$prev.attr("title", this.options.prevMonthButtonLabel),
          this.$prev.addClass("enabled"),
          this.$prev.removeClass("disabled"));
      this.$monthObj.attr("title", this.options.changeYearButtonLabel);
      this.options.max != null && this.year + 1 > this.options.max.getFullYear()
        ? (this.$next.attr("title", ""), this.$next.addClass("disabled"), this.$next.removeClass("enabled"))
        : (this.$next.attr("title", this.options.nextMonthButtonLabel),
          this.$next.addClass("enabled"),
          this.$next.removeClass("disabled"));
      var t = 0,
        r = 1,
        u = this.$grid.find("tbody");
      for (
        this.$monthObj.html(this.year),
          this.hideObject(this.$grid.find("thead")),
          u.empty(),
          n("#datepicker-err-msg-" + this.id).empty(),
          i = '\t<tr id="row0-' + this.id + '" role="row">\n',
          f = this.options.isYearDisabled && this.options.isYearDisabled(this.year),
          t = 0;
        t < 12;
        t++
      )
        (i += f
          ? '\t\t<td id="cell' + (t + 1) + "-" + this.id + '" class="month unselectable"'
          : t == this.month && this.year == this.curYear
          ? '\t\t<td id="cell' + (t + 1) + "-" + this.id + '" class="month curMonth selectable"'
          : this.options.min != null &&
            (this.year < this.options.min.getFullYear() ||
              (this.year == this.options.min.getFullYear() && t < this.options.min.getMonth()))
          ? '\t\t<td id="cell' + (t + 1) + "-" + this.id + '" class="month unselectable"'
          : this.options.max != null &&
            (this.year > this.options.max.getFullYear() ||
              (this.year == this.options.max.getFullYear() && t > this.options.max.getMonth()))
          ? '\t\t<td id="cell' + (t + 1) + "-" + this.id + '" class="month unselectable"'
          : this.options.isMonthDisabled && this.options.isMonthDisabled(this.year, t + 1)
          ? '\t\t<td id="cell' + (t + 1) + "-" + this.id + '" class="month unselectable"'
          : '\t\t<td id="cell' + (t + 1) + "-" + this.id + '" class="month selectable"'),
          (i += ' data-value="' + t + '"'),
          (i += ' title="' + this.locales.month_names[t] + " " + this.year + '"'),
          (i += ' aria-label="' + this.locales.month_names[t] + " " + this.year + '"'),
          (i += ' role="gridcell" tabindex="-1" aria-selected="false">' + this.locales.month_names_abbreviated[t]),
          (i += "</td>"),
          (t == 3 || t == 7) && ((i += '\t</tr>\n\t<tr id="row' + r + "-" + this.id + '" role="row">\n'), r++);
      i += "\t</tr>";
      u.append(i);
      this.gridType = 1;
    };
    i.prototype.populateYearsCalendar = function() {
      var r, t, u;
      this.$calendar.find(".datepicker-bn-prev-label").html(this.options.prevYearButtonLabel);
      this.$calendar.find(".datepicker-bn-next-label").html(this.options.nextYearButtonLabel);
      this.hideObject(this.$fastprev);
      this.hideObject(this.$fastnext);
      this.options.min != null && this.year - 20 < this.options.min.getFullYear()
        ? (this.$prev.attr("title", ""), this.$prev.addClass("disabled"), this.$prev.removeClass("enabled"))
        : (this.$prev.attr("title", this.options.prevYearButtonLabel),
          this.$prev.addClass("enabled"),
          this.$prev.removeClass("disabled"));
      this.$monthObj.attr("title", this.options.changeRangeButtonLabel);
      this.options.max != null && this.year + 20 > this.options.max.getFullYear()
        ? (this.$next.attr("title", ""), this.$next.addClass("disabled"), this.$next.removeClass("enabled"))
        : (this.$next.attr("title", this.options.nextYearButtonLabel),
          this.$next.addClass("enabled"),
          this.$next.removeClass("disabled"));
      var i = Math.floor(this.year / 10) * 10,
        f = i + 19,
        e = 1,
        o = this.$grid.find("tbody");
      for (
        this.$monthObj.html(i + "-" + f),
          this.hideObject(this.$grid.find("thead")),
          o.empty(),
          n("#datepicker-err-msg-" + this.id).empty(),
          r = '\t<tr id="row0-' + this.id + '" role="row">\n',
          t = i;
        t <= f;
        t++
      )
        (r +=
          t == this.year
            ? '\t\t<td id="cell' + (t - i + 1) + "-" + this.id + '" class="year curYear selectable"'
            : this.options.min != null && t < this.options.min.getFullYear()
            ? '\t\t<td id="cell' + (t - i + 1) + "-" + this.id + '" class="year unselectable"'
            : this.options.max != null && t > this.options.max.getFullYear()
            ? '\t\t<td id="cell' + (t - i + 1) + "-" + this.id + '" class="year unselectable"'
            : this.options.isYearDisabled && this.options.isYearDisabled(t)
            ? '\t\t<td id="cell' + (t - i + 1) + "-" + this.id + '" class="year unselectable"'
            : '\t\t<td id="cell' + (t - i + 1) + "-" + this.id + '" class="year selectable"'),
          (r += ' data-value="' + t + '"'),
          (r += ' title="' + t + '"'),
          (r += ' role="gridcell" tabindex="-1" aria-selected="false">' + t),
          (r += "</td>"),
          (u = t - i),
          (u == 4 || u == 9 || u == 14) &&
            ((r += '\t</tr>\n\t<tr id="row' + e + "-" + this.id + '" role="row">\n'), e++);
      r += "\t</tr>";
      o.append(r);
      this.gridType = 2;
    };
    i.prototype.showDaysOfPrevMonth = function(n) {
      var t = this.previousMonth(this.year, this.month),
        i,
        r;
      if (
        this.options.min != null &&
        (t.year < this.options.min.getFullYear() ||
          (t.year == this.options.min.getFullYear() && t.month < this.options.min.getMonth()))
      )
        return !1;
      if (((this.month = t.month), (this.year = t.year), this.populateDaysCalendar(), n != null)) {
        for (i = this.$grid.find("td"), n = i.length - n; n >= 0 && !i.eq(n).hasClass("selectable"); ) n--;
        n >= 0 && ((r = i.eq(n).attr("id")), this.$grid.attr("aria-activedescendant", r), this.selectGridCell(r));
      }
      return !0;
    };
    i.prototype.showDaysOfMonth = function(n) {
      if (
        (this.options.min != null &&
          (this.year < this.options.min.getFullYear() ||
            (this.year == this.options.min.getFullYear() && n < this.options.min.getMonth()))) ||
        (this.options.max != null &&
          (this.year > this.options.max.getFullYear() ||
            (this.year == this.options.max.getFullYear() && n > this.options.max.getMonth())))
      )
        return !1;
      this.month = n;
      this.date = Math.min(this.date, this.getDaysInMonth(this.year, this.month));
      this.populateDaysCalendar();
      var t = this.$grid.find("tbody td[data-value='" + this.date + "']");
      return this.selectGridCell(t.attr("id")), !0;
    };
    i.prototype.showMonthsOfPrevYear = function(n) {
      var t, i;
      if (this.options.min != null && this.year - 1 < this.options.min.getFullYear()) return !1;
      if ((this.year--, this.populateMonthsCalendar(), n != null)) {
        for (t = this.$grid.find("td"), n = t.length - n; n >= 0 && !t.eq(n).hasClass("selectable"); ) n--;
        n >= 0 && ((i = t.eq(n).attr("id")), this.$grid.attr("aria-activedescendant", i), this.selectGridCell(i));
      }
      return !0;
    };
    i.prototype.showMonthsOfYear = function(n) {
      if (
        (this.options.min != null && n < this.options.min.getFullYear()) ||
        (this.options.max != null && n > this.options.max.getFullYear())
      )
        return !1;
      this.year = n;
      this.populateMonthsCalendar();
      var t = this.$grid.find("tbody td[data-value='" + this.month + "']");
      return this.$grid.attr("aria-activedescendant", t.attr("id")), this.selectGridCell(t.attr("id")), !0;
    };
    i.prototype.showYearsOfPrevRange = function(n) {
      var t, i;
      if (this.options.min != null && this.year - 20 < this.options.min.getFullYear()) return !1;
      if (((this.year -= 20), this.populateYearsCalendar(), n != null)) {
        for (t = this.$grid.find("td"), n = t.length - n; n >= 0 && !t.eq(n).hasClass("selectable"); ) n--;
        n >= 0 && ((i = t.eq(n).attr("id")), this.$grid.attr("aria-activedescendant", i), this.selectGridCell(i));
      }
      return !0;
    };
    i.prototype.showDaysOfNextMonth = function(n) {
      var t = this.nextMonth(this.year, this.month),
        i,
        r;
      if (
        this.options.max != null &&
        (t.year > this.options.max.getFullYear() ||
          (t.year == this.options.max.getFullYear() && t.month > this.options.max.getMonth()))
      )
        return !1;
      if (((this.month = t.month), (this.year = t.year), this.populateDaysCalendar(), n != null)) {
        for (i = this.$grid.find("td"), n--; n < i.length && !i.eq(n).hasClass("selectable"); ) n++;
        n < i.length && ((r = i.eq(n).attr("id")), this.$grid.attr("aria-activedescendant", r), this.selectGridCell(r));
      }
      return !0;
    };
    i.prototype.showMonthsOfNextYear = function(n) {
      var t, i;
      if (this.options.max != null && this.year + 1 > this.options.max.getFullYear()) return !1;
      if ((this.year++, this.populateMonthsCalendar(), n != null)) {
        for (t = this.$grid.find("td"), n--; n < t.length && !t.eq(n).hasClass("selectable"); ) n++;
        n < t.length && ((i = t.eq(n).attr("id")), this.$grid.attr("aria-activedescendant", i), this.selectGridCell(i));
      }
      return !0;
    };
    i.prototype.showYearsOfNextRange = function(n) {
      var t, i;
      if (this.options.max != null && this.year + 20 > this.options.max.getFullYear()) return !1;
      if (((this.year += 20), this.populateYearsCalendar(), n != null)) {
        for (t = this.$grid.find("td"), n--; n < t.length && !t.eq(n).hasClass("selectable"); ) n++;
        n < t.length && ((i = t.eq(n).attr("id")), this.$grid.attr("aria-activedescendant", i), this.selectGridCell(i));
      }
      return !0;
    };
    i.prototype.showDaysOfPrevYear = function() {
      return this.options.min != null &&
        (this.year - 1 < this.options.min.getFullYear() ||
          (this.year - 1 == this.options.min.getFullYear() && this.month < this.options.min.getMonth()))
        ? !1
        : (this.year--, this.populateDaysCalendar(), !0);
    };
    i.prototype.showDaysOfNextYear = function() {
      return this.options.max != null &&
        (this.year + 1 > this.options.max.getFullYear() ||
          (this.year + 1 == this.options.max.getFullYear() && this.month > this.options.max.getMonth()))
        ? !1
        : (this.year++, this.populateDaysCalendar(), !0);
    };
    i.prototype.bindHandlers = function() {
      var t = this;
      this.$fastprev.on("click", function(n) {
        return t.handleFastPrevClick(n);
      });
      this.$prev.on("click", function(n) {
        return t.handlePrevClick(n);
      });
      this.$next.on("click", function(n) {
        return t.handleNextClick(n);
      });
      this.$fastnext.on("click", function(n) {
        return t.handleFastNextClick(n);
      });
      this.$monthObj.on("click", function(n) {
        return t.handleMonthClick(n);
      });
      this.$monthObj.on("keydown", function(n) {
        return t.handleMonthKeyDown(n);
      });
      this.$fastprev.on("keydown", function(n) {
        return t.handleFastPrevKeyDown(n);
      });
      this.$prev.on("keydown", function(n) {
        return t.handlePrevKeyDown(n);
      });
      this.$next.on("keydown", function(n) {
        return t.handleNextKeyDown(n);
      });
      this.$fastnext.on("keydown", function(n) {
        return t.handleFastNextKeyDown(n);
      });
      if (this.options.modal == !0) {
        this.$close.on("click", function(n) {
          return t.handleCloseClick(n);
        });
        this.$close.on("keydown", function(n) {
          return t.handleCloseKeyDown(n);
        });
      }
      this.$grid.on("keydown", function(n) {
        return t.handleGridKeyDown(n);
      });
      this.$grid.on("keypress", function(n) {
        return t.handleGridKeyPress(n);
      });
      this.$grid.on("focus", function(n) {
        return t.handleGridFocus(n);
      });
      this.$grid.on("blur", function(n) {
        return t.handleGridBlur(n);
      });
      this.$grid.on("click", "td", function(n) {
        return t.handleGridClick(this, n);
      });
      this.$target.on("change", function() {
        var i = t.parseDate(n(this).val());
        t.updateLinked(i);
      });
    };
    i.prototype.handleFastPrevClick = function(n) {
      if (this.showDaysOfPrevYear()) {
        var t = this.$grid.attr("aria-activedescendant");
        this.month != this.curMonth || this.year != this.curYear
          ? (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id))
          : (this.$grid.attr("aria-activedescendant", t), this.selectGridCell(t));
      }
      return n.stopPropagation(), !1;
    };
    i.prototype.handlePrevClick = function(n) {
      var t = this.$grid.attr("aria-activedescendant"),
        i;
      switch (this.gridType) {
        case 0:
          i = n.ctrlKey ? this.showDaysOfPrevYear() : this.showDaysOfPrevMonth();
          i &&
            (this.month != this.curMonth || this.year != this.curYear
              ? (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id))
              : (this.$grid.attr("aria-activedescendant", t), this.selectGridCell(t)));
          break;
        case 1:
          this.showMonthsOfPrevYear() &&
            (this.year != this.curYear
              ? (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id))
              : (this.$grid.attr("aria-activedescendant", t), this.selectGridCell(t)));
          break;
        case 2:
          this.showYearsOfPrevRange() &&
            (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id));
      }
      return n.stopPropagation(), !1;
    };
    i.prototype.handleMonthClick = function(n) {
      return this.changeGrid(n), n.stopPropagation(), !1;
    };
    i.prototype.handleNextClick = function(n) {
      var t = this.$grid.attr("aria-activedescendant"),
        i;
      switch (this.gridType) {
        case 0:
          i = n.ctrlKey ? this.showDaysOfNextYear() : this.showDaysOfNextMonth();
          i &&
            (this.month != this.curMonth || this.year != this.curYear
              ? (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id))
              : (this.$grid.attr("aria-activedescendant", t), this.selectGridCell(t)));
          break;
        case 1:
          this.showMonthsOfNextYear() &&
            (this.year != this.curYear
              ? (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id))
              : (this.$grid.attr("aria-activedescendant", t), this.selectGridCell(t)));
          break;
        case 2:
          this.showYearsOfNextRange() &&
            (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id));
      }
      return n.stopPropagation(), !1;
    };
    i.prototype.handleFastNextClick = function(n) {
      if (this.showDaysOfNextYear()) {
        var t = this.$grid.attr("aria-activedescendant");
        this.month != this.curMonth || this.year != this.curYear
          ? (this.$grid.attr("aria-activedescendant", "cell1-" + this.id), this.selectGridCell("cell1-" + this.id))
          : (this.$grid.attr("aria-activedescendant", t), this.selectGridCell(t));
      }
      return n.stopPropagation(), !1;
    };
    i.prototype.handleCloseClick = function(n) {
      return this.hide(), n.stopPropagation(), !1;
    };
    i.prototype.handleFastPrevKeyDown = function(n) {
      if (n.altKey) return !0;
      switch (n.keyCode) {
        case this.keys.tab:
          return this.options.modal == !1 || n.ctrlKey
            ? !0
            : (n.shiftKey ? this.$close.trigger("focus") : this.$prev.trigger("focus"), n.stopPropagation(), !1);
        case this.keys.enter:
        case this.keys.space:
          return n.shiftKey || n.ctrlKey ? !0 : (this.showDaysOfPrevYear(), n.stopPropagation(), !1);
        case this.keys.esc:
          return this.hide(), n.stopPropagation(), !1;
      }
      return !0;
    };
    i.prototype.handlePrevKeyDown = function(n) {
      if (n.altKey) return !0;
      switch (n.keyCode) {
        case this.keys.tab:
          return this.options.modal == !1 || n.ctrlKey
            ? !0
            : (n.shiftKey
                ? this.gridType == 0
                  ? this.$fastprev.trigger("focus")
                  : this.$close.trigger("focus")
                : this.$monthObj.trigger("focus"),
              n.stopPropagation(),
              !1);
        case this.keys.enter:
        case this.keys.space:
          if (n.shiftKey) return !0;
          switch (this.gridType) {
            case 0:
              n.ctrlKey ? this.showDaysOfPrevYear() : this.showDaysOfPrevMonth();
              break;
            case 1:
              this.showMonthsOfPrevYear();
              break;
            case 2:
              this.showYearsOfPrevRange();
          }
          return n.stopPropagation(), !1;
        case this.keys.esc:
          return this.hide(), n.stopPropagation(), !1;
      }
      return !0;
    };
    i.prototype.handleMonthKeyDown = function(n) {
      if (n.altKey) return !0;
      switch (n.keyCode) {
        case this.keys.tab:
          return this.options.modal == !1 || n.ctrlKey
            ? !0
            : (n.shiftKey ? this.$prev.trigger("focus") : this.$next.trigger("focus"), n.stopPropagation(), !1);
        case this.keys.enter:
        case this.keys.space:
          return this.changeGrid(n), n.stopPropagation(), !1;
        case this.keys.esc:
          return this.hide(), n.stopPropagation(), !1;
      }
      return !0;
    };
    i.prototype.handleNextKeyDown = function(n) {
      if (n.altKey) return !0;
      switch (n.keyCode) {
        case this.keys.tab:
          return this.options.modal == !1 || n.ctrlKey
            ? !0
            : (n.shiftKey
                ? this.$monthObj.focus()
                : this.gridType == 0
                ? this.$fastnext.trigger("focus")
                : this.$grid.trigger("focus"),
              n.stopPropagation(),
              !1);
        case this.keys.enter:
        case this.keys.space:
          switch (this.gridType) {
            case 0:
              n.ctrlKey ? this.showDaysOfNextYear() : this.showDaysOfNextMonth();
              break;
            case 1:
              this.showMonthsOfNextYear();
              break;
            case 2:
              this.showYearsOfNextRange();
          }
          return n.stopPropagation(), !1;
        case this.keys.esc:
          return this.hide(), n.stopPropagation(), !1;
      }
      return !0;
    };
    i.prototype.handleFastNextKeyDown = function(n) {
      if (n.altKey) return !0;
      switch (n.keyCode) {
        case this.keys.tab:
          return this.options.modal == !1 || n.ctrlKey
            ? !0
            : (n.shiftKey ? this.$next.trigger("focus") : this.$grid.trigger("focus"), n.stopPropagation(), !1);
        case this.keys.enter:
        case this.keys.space:
          return this.showDaysOfNextYear(), n.stopPropagation(), !1;
        case this.keys.esc:
          return this.hide(), n.stopPropagation(), !1;
      }
      return !0;
    };
    i.prototype.handleCloseKeyDown = function(n) {
      if (n.altKey) return !0;
      switch (n.keyCode) {
        case this.keys.tab:
          return n.ctrlKey
            ? !0
            : (n.shiftKey
                ? this.$grid.focus()
                : this.gridType == 0
                ? this.$fastprev.trigger("focus")
                : this.$prev.trigger("focus"),
              n.stopPropagation(),
              !1);
        case this.keys.enter:
        case this.keys.esc:
        case this.keys.space:
          return n.shiftKey || n.ctrlKey ? !0 : (this.hide(), n.stopPropagation(), !1);
      }
      return !0;
    };
    i.prototype.handleGridKeyDown = function(i) {
      var u = n("#" + this.$grid.attr("aria-activedescendant")),
        f = this.$grid.find("td.selectable"),
        a = this.$grid
          .find("tbody tr")
          .eq(0)
          .find("td").length,
        h,
        o,
        r,
        c,
        l,
        e,
        v,
        s;
      if (i.altKey && i.keyCode != this.keys.pageup && i.keyCode != this.keys.pagedown) return !0;
      switch (i.keyCode) {
        case this.keys.tab:
          return this.options.modal == !0
            ? (i.shiftKey
                ? this.gridType == 0
                  ? this.$fastnext.trigger("focus")
                  : this.$next.trigger("focus")
                : this.$close.trigger("focus"),
              i.stopPropagation(),
              !1)
            : (this.hide(), this.handleTabOut(i), i.stopPropagation(), !1);
        case this.keys.enter:
        case this.keys.space:
          if (i.ctrlKey) return !0;
          switch (this.gridType) {
            case 0:
              this.update();
              this.hide();
              break;
            case 1:
              this.showDaysOfMonth(parseInt(u.attr("data-value"), 10));
              break;
            case 2:
              this.showMonthsOfYear(parseInt(u.attr("data-value"), 10));
          }
          return i.stopPropagation(), !1;
        case this.keys.esc:
          return this.hide(), i.stopPropagation(), !1;
        case this.keys.left:
        case this.keys.right:
          if (
            (i.keyCode == this.keys.left && this.locales.directionality === "LTR") ||
            (i.keyCode == this.keys.right && this.locales.directionality === "RTL")
          ) {
            if (i.ctrlKey || i.shiftKey) return !0;
            if (((r = f.index(u) - 1), (h = null), r >= 0))
              (h = f.eq(r)),
                this.unSelectGridCell(u.attr("id")),
                this.$grid.attr("aria-activedescendant", h.attr("id")),
                this.selectGridCell(h.attr("id"));
            else
              switch (this.gridType) {
                case 0:
                  this.showDaysOfPrevMonth(0);
                  break;
                case 1:
                  this.showMonthsOfPrevYear(0);
                  break;
                case 2:
                  this.showYearsOfPrevRange(0);
              }
            return i.stopPropagation(), !1;
          }
          if (i.ctrlKey || i.shiftKey) return !0;
          if (((r = f.index(u) + 1), (c = null), r < f.length))
            (c = f.eq(r)),
              this.unSelectGridCell(u.attr("id")),
              this.$grid.attr("aria-activedescendant", c.attr("id")),
              this.selectGridCell(c.attr("id"));
          else
            switch (this.gridType) {
              case 0:
                this.showDaysOfNextMonth(1);
                break;
              case 1:
                this.showMonthsOfNextYear(1);
                break;
              case 2:
                this.showYearsOfNextRange(1);
            }
          return i.stopPropagation(), !1;
        case this.keys.up:
          if (i.ctrlKey || i.shiftKey) return !0;
          for (var o = this.$grid.find("td"), r = o.index(u) - a, h = null; r >= 0 && !o.eq(r).hasClass("selectable"); )
            r--;
          if (r >= 0)
            (h = o.eq(r)),
              this.unSelectGridCell(u.attr("id")),
              this.$grid.attr("aria-activedescendant", h.attr("id")),
              this.selectGridCell(h.attr("id"));
          else {
            r = a - (o.index(u) % a);
            switch (this.gridType) {
              case 0:
                this.showDaysOfPrevMonth(r);
                break;
              case 1:
                this.showMonthsOfPrevYear(r);
                break;
              case 2:
                this.showYearsOfPrevRange(r);
            }
          }
          return i.stopPropagation(), !1;
        case this.keys.down:
          if (i.ctrlKey || i.shiftKey) return !0;
          for (o = this.$grid.find("td"), r = o.index(u) + a; r < o.length && !o.eq(r).hasClass("selectable"); ) r++;
          if (r < o.length)
            (c = o.eq(r)),
              this.unSelectGridCell(u.attr("id")),
              this.$grid.attr("aria-activedescendant", c.attr("id")),
              this.selectGridCell(c.attr("id"));
          else {
            r = (o.index(u) % a) + 1;
            switch (this.gridType) {
              case 0:
                this.showDaysOfNextMonth(r);
                break;
              case 1:
                this.showMonthsOfNextYear(r);
                break;
              case 2:
                this.showYearsOfNextRange(r);
            }
          }
          return i.stopPropagation(), !1;
        case this.keys.pageup:
          if (((l = this.$grid.attr("aria-activedescendant")), i.shiftKey || i.ctrlKey)) return !0;
          i.preventDefault();
          e = !1;
          switch (this.gridType) {
            case 0:
              i.altKey
                ? (i.stopImmediatePropagation(), (e = this.showDaysOfPrevYear()))
                : (e = this.showDaysOfPrevMonth());
              break;
            case 1:
              e = this.showMonthsOfPrevYear();
              break;
            case 2:
              e = this.showYearsOfPrevRange();
          }
          return (
            e &&
              (n("#" + l).attr("id") == t
                ? ((f = this.$grid.find("td.selectable")),
                  (s = f.eq(f.length - 1)),
                  this.$grid.attr("aria-activedescendant", s.attr("id")),
                  this.selectGridCell(s.attr("id")))
                : this.selectGridCell(l)),
            i.stopPropagation(),
            !1
          );
        case this.keys.pagedown:
          if (((l = this.$grid.attr("aria-activedescendant")), i.shiftKey || i.ctrlKey)) return !0;
          i.preventDefault();
          e = !1;
          switch (this.gridType) {
            case 0:
              i.altKey
                ? (i.stopImmediatePropagation(), (e = this.showDaysOfNextYear()))
                : (e = this.showDaysOfNextMonth());
              break;
            case 1:
              e = this.showMonthsOfNextYear();
              break;
            case 2:
              e = this.showYearsOfNextRange();
          }
          return (
            e &&
              (n("#" + l).attr("id") == t
                ? ((f = this.$grid.find("td.selectable")),
                  (s = f.eq(f.length - 1)),
                  this.$grid.attr("aria-activedescendant", s.attr("id")),
                  this.selectGridCell(s.attr("id")))
                : this.selectGridCell(l)),
            i.stopPropagation(),
            !1
          );
        case this.keys.home:
          return i.ctrlKey || i.shiftKey
            ? !0
            : ((v = f.eq(0)),
              this.unSelectGridCell(u.attr("id")),
              this.$grid.attr("aria-activedescendant", v.attr("id")),
              this.selectGridCell(v.attr("id")),
              i.stopPropagation(),
              !1);
        case this.keys.end:
          return i.ctrlKey || i.shiftKey
            ? !0
            : ((s = f.eq(f.length - 1)),
              this.unSelectGridCell(u.attr("id")),
              this.$grid.attr("aria-activedescendant", s.attr("id")),
              this.selectGridCell(s.attr("id")),
              i.stopPropagation(),
              !1);
      }
      return !0;
    };
    i.prototype.handleGridKeyPress = function(n) {
      if (n.altKey) return !0;
      switch (n.keyCode) {
        case this.keys.tab:
        case this.keys.enter:
        case this.keys.space:
        case this.keys.esc:
        case this.keys.left:
        case this.keys.right:
        case this.keys.up:
        case this.keys.down:
        case this.keys.pageup:
        case this.keys.pagedown:
        case this.keys.home:
        case this.keys.end:
          return n.stopPropagation(), !1;
      }
      return !0;
    };
    i.prototype.handleGridClick = function(t, i) {
      var r = n(t);
      if (r.is(".empty") || r.is(".unselectable")) return !0;
      this.$grid
        .find(".focus")
        .removeClass("focus")
        .attr("aria-selected", "false")
        .attr("tabindex", -1);
      switch (this.gridType) {
        case 0:
          this.$grid.attr("aria-activedescendant", r.attr("id"));
          this.selectGridCell(r.attr("id"));
          this.update();
          this.hide();
          break;
        case 1:
          this.showDaysOfMonth(parseInt(r.attr("data-value"), 10));
          break;
        case 2:
          this.showMonthsOfYear(parseInt(r.attr("data-value"), 10));
      }
      return i.stopPropagation(), !1;
    };
    i.prototype.handleGridFocus = function() {
      var u = this.$grid.attr("aria-activedescendant"),
        i,
        r;
      return (
        n("#" + u).attr("id") == t
          ? ((i = this.$grid.find("td.selectable")),
            (r = i.eq(i.length - 1)),
            this.$grid.attr("aria-activedescendant", r.attr("id")),
            this.selectGridCell(r.attr("id")))
          : this.selectGridCell(u),
        !0
      );
    };
    i.prototype.handleGridBlur = function() {
      return this.unSelectGridCell(this.$grid.attr("aria-activedescendant")), !0;
    };
    i.prototype.handleTabOut = function(t) {
      var r = n("body").find(
          ":input:visible:not([disabled], .datepicker-button), a[href]:visible:not([disabled], .datepicker-button)"
        ),
        i = r.index(this.$target);
      return i > -1 && i < r.length && (t.shiftKey ? i > 0 && i-- : i + 1 < r.length && i++, r.eq(i).focus()), !0;
    };
    i.prototype.changeGrid = function(n) {
      var t;
      switch (this.gridType) {
        case 0:
          this.populateMonthsCalendar();
          this.year != this.curYear
            ? ((t = this.$grid.find("td.selectable")), this.$grid.attr("aria-activedescendant", t.eq(0).attr("id")))
            : this.$grid.attr("aria-activedescendant", this.$grid.find(".curMonth").attr("id"));
          this.selectGridCell(this.$grid.attr("aria-activedescendant"));
          break;
        case 2:
          n.shiftKey ? (this.year -= 20) : (this.year += 20);
        case 1:
          this.populateYearsCalendar();
          this.year != this.curYear
            ? ((t = this.$grid.find("td.selectable")), this.$grid.attr("aria-activedescendant", t.eq(0).attr("id")))
            : this.$grid.attr("aria-activedescendant", this.$grid.find(".curYear").attr("id"));
          this.selectGridCell(this.$grid.attr("aria-activedescendant"));
      }
      return !0;
    };
    i.prototype.selectGridCell = function(t) {
      n("#" + t)
        .addClass("focus")
        .attr("aria-selected", "true")
        .attr("tabindex", 0)
        .focus();
    };
    i.prototype.unSelectGridCell = function(t) {
      n("#" + t)
        .removeClass("focus")
        .attr("aria-selected", "false")
        .attr("tabindex", -1);
    };
    i.prototype.update = function() {
      var i = n("#" + this.$grid.attr("aria-activedescendant")),
        r = new Date(this.year, this.month, parseInt(i.attr("data-value"), 10)),
        t = this.formatDate(r, this.options.outputFormat);
      if (
        (this.$target.val(t),
        this.$target.removeAttr("aria-invalid"),
        this.$target.parents(".form-group").removeClass("has-error"),
        this.$target.trigger("change"),
        this.options.onUpdate)
      )
        this.options.onUpdate(t);
    };
    i.prototype.updateLinked = function(n) {
      var r, t, u, i;
      this.options.previous !== null &&
        this.options.previous.val() !== "" &&
        ((r = this.options.previous.datepicker("getDate")),
        this.options.allowSameDate
          ? r > n &&
            ((t = this.formatDate(n, this.options.previous.datepicker("outputFormat"))), this.options.previous.val(t))
          : r >= n &&
            ((t = this.formatDate(new Date(n.getTime() - 864e5), this.options.previous.datepicker("outputFormat"))),
            this.options.previous.val(t)));
      this.options.next !== null &&
        this.options.next.val() !== "" &&
        ((u = this.options.next.datepicker("getDate")),
        this.options.allowSameDate
          ? u < n && ((i = this.formatDate(n, this.options.next.datepicker("outputFormat"))), this.options.next.val(i))
          : u <= n &&
            ((i = this.formatDate(new Date(n.getTime() + 864e5), this.options.next.datepicker("outputFormat"))),
            this.options.next.val(i)));
      this.options.next !== null &&
        (this.options.allowSameDate
          ? this.options.next.datepicker("min", n)
          : this.options.next.datepicker("min", new Date(n.getTime() + 864e5)));
    };
    i.prototype.hideObject = function(n) {
      n.attr("aria-hidden", !0);
      n.hide();
    };
    i.prototype.showObject = function(n) {
      n.attr("aria-hidden", !1);
      n.show();
    };
    i.prototype.show = function() {
      var t = this,
        i;
      if ((n(".datepicker-calendar").trigger("ab.datepicker.opening", [t.id]), this.options.modal == !0)) {
        this.modalEventHandler ||
          (this.modalEventHandler = function(n) {
            return t.$grid.focus(), n.stopPropagation, !1;
          });
        n(document).on("click mousedown mouseup", this.modalEventHandler);
        this.greyOut(!0);
        i = parseInt(n("#datepicker-overlay").css("z-index"), 10) || 40;
        this.$calendar.css("z-index", i + 1);
      } else {
        n(document).on("click", n.proxy(this.handleDocumentClick, this));
        this.$calendar.on("ab.datepicker.opening", function(n, i) {
          i != t.id ? t.hide() : t.$grid.focus();
        });
      }
      this.$calendar.on("ab.datepicker.opened", function(n, i) {
        i == t.id && t.$grid.focus();
      });
      var r = Math.max(0, Math.floor(this.$group[0].offsetTop)),
        u = Math.max(0, Math.floor(this.$group[0].offsetLeft + this.$target[0].offsetLeft)),
        f = this.$calendar.outerHeight(),
        e = this.$group.offset().top,
        o = this.$group.outerHeight(!0),
        h = Math.floor(e - n(window).scrollTop()),
        s = Math.floor(n(window).height() - (e + o - n(window).scrollTop()));
      s < f && s < h
        ? (this.$calendar.addClass("above"), this.$calendar.css({ top: r - f + "px", left: u + "px" }))
        : (this.$calendar.addClass("below"), this.$calendar.css({ top: r + o + "px", left: u + "px" }));
      this.$calendar.attr("aria-hidden", "false");
      this.$calendar.fadeIn();
      n(".datepicker-calendar").trigger("ab.datepicker.opened", [t.id]);
    };
    i.prototype.refresh = function() {
      this.drawCalendarHeader();
      switch (this.gridType) {
        case 0:
          this.populateDaysCalendar();
          break;
        case 1:
          this.populateMonthsCalendar();
          break;
        case 2:
          this.populateYearsCalendar();
      }
    };
    i.prototype.handleDocumentClick = function(t) {
      return n(t.target).parents("#datepicker-calendar-" + this.id).length == 0
        ? (this.hide(), !0)
        : (this.$grid.focus(), t.stopPropagation, !1);
    };
    i.prototype.hide = function(t) {
      if (this.options.inline == !1) {
        var i = this;
        this.options.modal == !0
          ? (this.modalEventHandler && n(document).off("click mousedown mouseup", this.modalEventHandler),
            this.greyOut(!1))
          : (n(document).off("click", i.handleDocumentClick), this.$calendar.off("ab.datepicker.opening"));
        this.$calendar.removeClass("above below");
        this.$calendar.attr("aria-hidden", "true");
        this.$calendar.fadeOut();
        n(".datepicker-calendar").trigger("ab.datepicker.closed", [i.id]);
        t || this.$target.trigger("focus");
      }
    };
    i.prototype.greyOut = function(t) {
      var i = n("#datepicker-overlay");
      i.length == 0 &&
        t &&
        (n("body").append('<div id="datepicker-overlay" class="datepicker-overlay"></div>'),
        (i = n("#datepicker-overlay")));
      t ? i.fadeIn(500) : i.fadeOut(500);
    };
    i.prototype.getDaysInMonth = function(n, t) {
      return 32 - new Date(n, t, 32).getDate();
    };
    i.prototype.previousMonth = function(n, t) {
      return t == 0 ? ((t = 11), n--) : t--, { year: n, month: t };
    };
    i.prototype.nextMonth = function(n, t) {
      return t == 11 ? ((t = 0), n++) : t++, { year: n, month: t };
    };
    i.prototype.formatDate = function(n, t) {
      var u = function(n) {
          return (n < 0 || n > 9 ? "" : "0") + n;
        },
        p = function(n) {
          return Math.ceil((n.getDate() - 1 - n.getDay()) / 7);
        },
        w = function(n) {
          var t = new Date(n.getFullYear(), 0, 1);
          return Math.ceil(((n - t) / 864e5 + t.getDay() + 1) / 7);
        },
        b = function(n) {
          var t = new Date(n.getFullYear(), 0, 0);
          return Math.floor((n - t) / 864e5);
        },
        o = function(n) {
          var t = new Date(n.getTime());
          return t.setHours(0), n - t;
        },
        h = n.getFullYear() + "",
        f = n.getMonth() + 1,
        l = n.getDate(),
        c = b(n),
        r = n.getDay(),
        i = n.getHours(),
        a = n.getMinutes(),
        v = n.getSeconds(),
        y = w(n),
        k = p(n),
        d = Math.floor(n.getDate() / 7) + 1,
        e = Math.ceil((n.getMonth() + 1) / 3),
        s = n.getFullYear() < 1 ? 0 : 1,
        g = {
          y: "" + h,
          yyyy: h,
          yy: h.substring(2, 4),
          L: f,
          LL: u(f),
          LLL: this.locales.month_names_abbreviated[f - 1],
          LLLL: this.locales.month_names[f - 1],
          LLLLL: this.locales.month_names_narrow[f - 1],
          M: f,
          MM: u(f),
          MMM: this.locales.month_names_abbreviated[f - 1],
          MMMM: this.locales.month_names[f - 1],
          MMMMM: this.locales.month_names_narrow[f - 1],
          d: l,
          dd: u(l),
          D: c,
          DD: c,
          DDD: c,
          A: Math.round(o(n) * Math.pow(10, -2)),
          AA: Math.round(o(n) * Math.pow(10, -1)),
          AAA: Math.round(o(n) * Math.pow(10, 0)),
          AAAA: Math.round(o(n) * Math.pow(10, 1)),
          AAAAA: Math.round(o(n) * Math.pow(10, 2)),
          AAAAAA: Math.round(o(n) * Math.pow(10, 3)),
          E: this.locales.day_names_abbreviated[r],
          EE: this.locales.day_names_abbreviated[r],
          EEE: this.locales.day_names_abbreviated[r],
          EEEE: this.locales.day_names[r],
          EEEEE: this.locales.day_names_narrow[r],
          EEEEEE: this.locales.day_names_short[r],
          e: r,
          ee: r,
          eee: this.locales.day_names_abbreviated[r],
          eeee: this.locales.day_names[r],
          eeeee: this.locales.day_names_narrow[r],
          eeeeee: this.locales.day_names_short[r],
          c: r,
          cc: r,
          ccc: this.locales.day_names_abbreviated[r],
          cccc: this.locales.day_names[r],
          ccccc: this.locales.day_names_narrow[r],
          cccccc: this.locales.day_names_short[r],
          F: d,
          G: this.locales.era_names_abbreviated[s],
          GG: this.locales.era_names_abbreviated[s],
          GGG: this.locales.era_names_abbreviated[s],
          GGGG: this.locales.era_names[s],
          GGGGG: this.locales.era_names_narrow[s],
          Q: e,
          QQ: u(e),
          QQQ: this.locales.quarter_names_abbreviated[e - 1],
          QQQQ: this.locales.quarter_names[e - 1],
          QQQQQ: this.locales.quarter_names_narrow[e - 1],
          q: e,
          qq: u(e),
          qqq: this.locales.quarter_names_abbreviated[e - 1],
          qqqq: this.locales.quarter_names[e - 1],
          qqqqq: this.locales.quarter_names_narrow[e - 1],
          H: i,
          HH: u(i),
          h: i == 0 ? 12 : i > 12 ? i - 12 : i,
          hh: u(i == 0 ? 12 : i > 12 ? i - 12 : i),
          K: i > 11 ? i - 12 : i,
          k: i + 1,
          KK: u(i > 11 ? i - 12 : i),
          kk: u(i + 1),
          a: i > 11 ? this.locales.day_periods.pm : this.locales.day_periods.am,
          m: a,
          mm: u(a),
          s: v,
          ss: u(v),
          w: y,
          ww: u(y),
          W: k
        };
      return t.replace(
        /('[^']+'|y{1,4}|L{1,5}|M{1,5}|c{1,6}|d{1,2}|D{1,3}|E{1,6}|e{1,6}|F{1,1}|G{1,5}|Q{1,5}|q{1,5}|H{1,2}|h{1,2}|K{1,2}|k{1,2}|m{1,2}|s{1,2}|w{1,2}|W{1,1}|A{1,6})/g,
        function(n) {
          return n.charAt(0) === "'" ? n.substr(1, n.length - 2) : g[n] || n;
        }
      );
    };
    i.prototype.createDateFromFormat = function(t, i) {
      var o = function(n, t, i, r) {
          for (var f, u = r; u >= i; u--) {
            if (((f = n.substring(t, t + u)), f.length < i)) return null;
            if (/^\d+$/.test(f)) return f;
          }
          return null;
        },
        v = function(n, t) {
          for (var u, r = 0; r < n.length; r++)
            if (((u = n[r]), i.substring(t, t + u.length).toLowerCase() == u.toLowerCase())) return u.length;
          return 0;
        },
        r = 0,
        y = new Date(),
        f = y.getYear(),
        e = y.getMonth() + 1,
        h = 1,
        u = 0,
        c = 0,
        l = 0,
        a = "",
        s = this;
      if (
        (n.each(t.match(/(.).*?\1*/g), function(n, t) {
          var y, p, w, b;
          switch (t) {
            case "yyyy":
              f = o(i, r, 4, 4);
              f != null && (r += f.length);
              break;
            case "yy":
              f = o(i, r, 2, 2);
              f != null && (r += f.length);
              break;
            case "y":
              f = o(i, r, 2, 4);
              f != null && (r += f.length);
              break;
            case "MMM":
            case "LLL":
              for (e = 0, y = 0; y < s.locales.month_names_abbreviated.length; y++)
                if (
                  ((p = s.locales.month_names_abbreviated[y]),
                  i.substring(r, r + p.length).toLowerCase() == p.toLowerCase())
                ) {
                  e = y + 1;
                  r += p.length;
                  break;
                }
              break;
            case "MMMM":
            case "LLLL":
              for (e = 0, y = 0; y < s.locales.month_names.length; y++)
                if (((p = s.locales.month_names[y]), i.substring(r, r + p.length).toLowerCase() == p.toLowerCase())) {
                  e = y + 1;
                  r += p.length;
                  break;
                }
              break;
            case "EEE":
            case "EE":
            case "E":
            case "eee":
              r += v(s.locales.day_names_abbreviated, r);
              break;
            case "EEEE":
            case "eeee":
            case "cccc":
              r += v(s.locales.day_names, r);
              break;
            case "EEEEEE":
            case "eeeeee":
            case "cccccc":
              r += v(s.locales.day_names_short, r);
              break;
            case "MM":
            case "M":
            case "LL":
            case "L":
              if (((e = o(i, r, t.length, 2)), e == null || e < 1 || e > 12)) return null;
              r += e.length;
              break;
            case "dd":
            case "d":
              if (((h = o(i, r, t.length, 2)), h == null || h < 1 || h > 31)) return null;
              r += h.length;
              break;
            case "hh":
            case "h":
              if (((u = o(i, r, t.length, 2)), u == null || u < 1 || u > 12)) return null;
              r += u.length;
              break;
            case "HH":
            case "H":
              if (((u = o(i, r, t.length, 2)), u == null || u < 0 || u > 23)) return null;
              r += u.length;
              break;
            case "KK":
            case "K":
              if (((u = o(i, r, t.length, 2)), u == null || u < 0 || u > 11)) return null;
              r += u.length;
              break;
            case "kk":
            case "k":
              if (((u = o(i, r, t.length, 2)), u == null || u < 1 || u > 24)) return null;
              r += u.length;
              u--;
              break;
            case "mm":
            case "m":
              if (((c = o(i, r, t.length, 2)), c == null || c < 0 || c > 59)) return null;
              r += c.length;
              break;
            case "ss":
            case "s":
              if (((l = o(i, r, t.length, 2)), l == null || l < 0 || l > 59)) return null;
              r += l.length;
              break;
            case "a":
              if (
                ((w = s.locales.day_periods.am.length),
                (b = s.locales.day_periods.pm.length),
                i.substring(r, r + w) == s.locales.day_periods.am)
              )
                (a = "AM"), (r += w);
              else if (i.substring(r, r + b) == s.locales.day_periods.pm) (a = "PM"), (r += b);
              else return null;
              break;
            default:
              if (i.substring(r, r + t.length) != t) return null;
              r += t.length;
          }
        }),
        r != i.length) ||
        f == null ||
        (f.length == 2 && (f = f > 50 ? 1900 + +f : 2e3 + +f), e < 1 || e > 12)
      )
        return null;
      if (e == 2)
        if ((f % 4 == 0 && f % 100 != 0) || f % 400 == 0) {
          if (h > 29) return null;
        } else if (h > 28) return null;
      return (e == 4 || e == 6 || e == 9 || e == 11) && h > 30
        ? null
        : (u < 12 && a == "PM" ? (u = +u + 12) : u > 11 && a == "AM" && (u -= 12), new Date(f, e - 1, h, u, c, l));
    };
    i.prototype.parseDate = function(t) {
      var i = null,
        r = this;
      return (
        n.each(this.options.inputFormat, function(n, u) {
          return (i = r.createDateFromFormat(u, t)), i != null ? !1 : void 0;
        }),
        i == null && (i = r.createDateFromFormat(this.options.outputFormat, t)),
        i
      );
    };
    i.prototype.min = function(n) {
      return (
        n != null &&
          ((this.options.min = n instanceof Date ? n : this.parseDate(n)),
          this.options.min != null &&
            this.dateObj < this.options.min &&
            (this.$target.attr("aria-invalid", !0),
            this.$target.parents(".form-group").addClass("has-error"),
            (this.dateObj = this.options.min)),
          this.options.inline != !1 && this.refresh()),
        this.options.min
      );
    };
    i.prototype.max = function(n) {
      return (
        n != null &&
          ((this.options.max = n instanceof Date ? n : this.parseDate(n)),
          this.options.max != null &&
            this.dateObj > this.options.max &&
            (this.$target.attr("aria-invalid", !0),
            this.$target.parents(".form-group").addClass("has-error"),
            (this.dateObj = this.options.max)),
          this.options.inline != !1 && this.refresh()),
        this.options.max
      );
    };
    i.prototype.next = function(t) {
      return (
        t != null &&
          (typeof t == "string"
            ? (this.options.next = n(t))
            : this.options.next instanceof n && (this.options.next = t)),
        this.options.next
      );
    };
    i.prototype.previous = function(t) {
      return (
        t != null &&
          (typeof t == "string"
            ? (this.options.previous = n(t))
            : this.options.previous instanceof n && (this.options.previous = t)),
        this.options.previous
      );
    };
    i.prototype.theme = function(n) {
      return (
        n != null &&
          (this.$button.removeClass(this.options.theme),
          this.$calendar.removeClass(this.options.theme),
          (this.options.theme = n),
          this.$button.addClass(this.options.theme),
          this.$calendar.addClass(this.options.theme)),
        this.options.theme
      );
    };
    i.prototype.firstDayOfWeek = function(n) {
      return (
        n != null &&
          ((this.options.firstDayOfWeek = parseInt(n, 10)),
          this.options.inline == !1 ? this.drawCalendarHeader() : this.refresh()),
        this.options.firstDayOfWeek
      );
    };
    i.prototype.daysOfWeekDisabled = function(t) {
      if (t != null) {
        this.options.daysOfWeekDisabled = [];
        Array.isArray(t) || (t = [t]);
        var i = this;
        n.each(t, function(n, t) {
          typeof t == "number"
            ? i.options.daysOfWeekDisabled.push(t)
            : typeof t == "string" && i.options.daysOfWeekDisabled.push(parseInt(t, 10));
        });
      }
      return this.options.daysOfWeekDisabled;
    };
    i.prototype.weekDayFormat = function(n) {
      return n != null && ((this.options.weekDayFormat = n), this.drawCalendarHeader()), this.options.weekDayFormat;
    };
    i.prototype.inputFormat = function(n) {
      return (
        n != null &&
          (Array.isArray(n) || (n = [n]),
          this.$target.attr("placeholder") == this.options.inputFormat[0] && this.$target.attr("placeholder", n[0]),
          (this.options.inputFormat = n)),
        this.options.inputFormat
      );
    };
    i.prototype.outputFormat = function(n) {
      return n != null && (this.options.outputFormat = n), this.options.outputFormat;
    };
    i.prototype.modal = function(n) {
      if (n != null)
        if (((this.options.modal = n), this.options.modal == !0)) {
          this.options.inline == !1 &&
            (this.showObject(this.$calendar.find(".datepicker-close-wrap")),
            this.showObject(this.$calendar.find(".datepicker-bn-close-label")));
          this.$close = this.$calendar.find(".datepicker-close");
          this.$close.html(this.options.closeButtonTitle).attr("title", this.options.closeButtonLabel);
          this.$calendar.find(".datepicker-bn-close-label").html(this.options.closeButtonLabel);
          var t = this;
          this.$close.on("click", function(n) {
            return t.handleCloseClick(n);
          });
          this.$close.on("keydown", function(n) {
            return t.handleCloseKeyDown(n);
          });
        } else
          this.hideObject(this.$calendar.find(".datepicker-close-wrap")),
            this.hideObject(this.$calendar.find(".datepicker-bn-close-label"));
      return this.options.modal;
    };
    i.prototype.inline = function(t) {
      if (t != null)
        if (t != !1) {
          this.hideObject(this.$button);
          this.hideObject(this.$calendar.find(".datepicker-close-wrap"));
          this.hideObject(this.$calendar.find(".datepicker-bn-close-label"));
          var i = typeof t == "string" ? n("#" + t) : t;
          i.append(this.$calendar);
          this.$calendar.css({ position: "relative", left: "0px", top: "0px" });
          this.options.inline = t;
          this.initializeDate();
          this.showObject(this.$calendar);
        } else
          this.$target.parent().after(this.$calendar),
            this.showObject(this.$button),
            this.options.modal == !0 &&
              (this.showObject(this.$calendar.find(".datepicker-close-wrap")),
              this.showObject(this.$calendar.find(".datepicker-bn-close-label"))),
            this.$calendar.parent().css("position") === "static" && this.$calendar.parent().css("position", "relative"),
            this.$calendar.css({ position: "absolute" }),
            (this.options.inline = t),
            this.hide();
      return this.options.inline;
    };
    i.prototype.format = function(n) {
      return this.formatDate(n, this.options.outputFormat);
    };
    i.prototype.enable = function() {
      this.$button.removeClass("disabled");
      this.$button.attr("aria-disabled", !1);
      this.$button.attr("tabindex", 0);
    };
    i.prototype.disable = function() {
      this.hide();
      this.$button.addClass("disabled");
      this.$button.attr("aria-disabled", !0);
      this.$button.attr("tabindex", -1);
    };
    i.prototype.datesDisabled = function(t) {
      this.options.datesDisabled = [];
      Array.isArray(t) || (t = [t]);
      var i = this;
      n.each(t, function(n, t) {
        if (typeof t == "string") {
          var r = i.parseDate(t);
          r !== null && i.options.datesDisabled.push(i.format(r));
        } else t instanceof Date && !isNaN(t.valueOf()) && i.options.datesDisabled.push(i.format(t));
      });
    };
    i.prototype.startview = function(n) {
      switch (n) {
        case 1:
        case "months":
          this.options.startView = 1;
          break;
        case 2:
        case "years":
          this.options.startView = 2;
          break;
        default:
          this.options.startView = 0;
      }
    };
    i.prototype.setLocales = function(n) {
      this.locales = n;
      this.options.inputFormat = [this.locales.short_format];
      this.options.outputFormat = this.locales.short_format;
      this.options.titleFormat = this.locales.full_format;
      this.options.firstDayOfWeek = this.locales.firstday_of_week;
      this.options.buttonTitle = this.locales.texts.buttonTitle;
      this.$button.find("span").attr("title", this.options.buttonTitle);
      this.options.buttonLabel = this.locales.texts.buttonLabel;
      this.options.prevButtonLabel = this.locales.texts.prevButtonLabel;
      this.options.prevMonthButtonLabel = this.locales.texts.prevMonthButtonLabel;
      this.options.prevYearButtonLabel = this.locales.texts.prevYearButtonLabel;
      this.options.nextButtonLabel = this.locales.texts.nextButtonLabel;
      this.options.nextMonthButtonLabel = this.locales.texts.nextMonthButtonLabel;
      this.options.nextYearButtonLabel = this.locales.texts.nextYearButtonLabel;
      this.options.changeMonthButtonLabel = this.locales.texts.changeMonthButtonLabel;
      this.options.changeYearButtonLabel = this.locales.texts.changeYearButtonLabel;
      this.options.changeRangeButtonLabel = this.locales.texts.changeRangeButtonLabel;
      this.options.closeButtonTitle = this.locales.texts.closeButtonTitle;
      this.options.closeButtonLabel = this.locales.texts.closeButtonLabel;
      this.options.calendarHelp = this.locales.texts.calendarHelp;
      this.drawCalendarHeader();
      this.locales.directionality === "RTL" ? this.$grid.addClass("rtl") : this.$grid.removeClass("rtl");
    };
    r = n.fn.datepicker;
    n.fn.datepicker = function(t, r) {
      var u, f;
      if (typeof t == "string" && n(this).length == 1) {
        if (
          ((u = n(this)
            .eq(0)
            .data("ab.datepicker")),
          u)
        )
          return u[t](r);
        f = n(this);
        setTimeout(function() {
          var n = f.eq(0).data("ab.datepicker");
          if (n) return n[t](r);
        }, 0);
      } else
        return this.each(function() {
          var u = n(this),
            f = u.data("ab.datepicker"),
            e = n.extend({}, i.DEFAULTS, u.data(), typeof t == "object" && t);
          f || u.data("ab.datepicker", (f = new i(this, e)));
          typeof t == "string" && f[t](r);
        });
    };
    n.fn.datepicker.Constructor = i;
    n.fn.datepicker.noConflict = function() {
      return (n.fn.datepicker = r), this;
    };
  });
